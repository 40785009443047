import { Analytics } from '@vercel/analytics/react'
import { Layout } from 'components/layout'
import Head from 'next/head'
import 'styles/globals.css'

function App({ Component, pageProps }) {
  return (
    <Layout>
      <Head>
        <title>Nova Unda</title>
        <meta name='description' content='Nova Unda Website' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <Component {...pageProps} />
      <Analytics />
    </Layout>
  )
}

export default App
