import LogoMark from 'assets/svg/logo-mark.svg'
import { Fade as Hamburger } from 'hamburger-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ComponentProps, useEffect, useState } from 'react'

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <main className='relative flex min-h-screen w-full flex-col items-center overflow-hidden'>
      <div className='isolate flex w-full flex-col items-stretch'>{children}</div>
      <Footer />
      <Header />
    </main>
  )
}

export function Footer() {
  return (
    <div className='bg-twilight-900 relative flex w-full justify-center px-8 pb-32 pt-12'>
      <div className='flex w-full max-w-4xl'>
        <div className='h-24 flex-1 lg:h-36'>
          <LogoMark height='100%' />
        </div>
        <div className='text-light flex flex-col items-start gap-4'>
          <a className='underline' href='mailto:hello@novaunda.com'>
            hello@novaunda.com
          </a>
          <a className='underline' href='tel:+40724055116'>
            +40724055116
          </a>
          <div className='h-8' />
          <p className='text-lg font-semibold'>Company</p>
          <p className='text-lg font-semibold'>Services</p>
          <p className='text-lg font-semibold'>Expertise</p>
        </div>

        <small className='text-light/40 absolute bottom-4 left-0 right-0 mx-auto inline-block w-max'>
          Copyright &copy; 2023, Nova Unda
        </small>
      </div>
    </div>
  )
}

export function Header() {
  return (
    <header className='fixed top-0 flex w-full items-center justify-center px-6 py-4'>
      <div className='bg-twilight/10 translate-z-0 absolute inset-0 saturate-[120%] backdrop-blur-md backdrop-brightness-50 backdrop-saturate-150 will-change-transform' />
      <div className='flex w-full max-w-6xl items-center'>
        <Logo />
        <div className='flex-1' />
        <MobileMenu />
        <DesktopMenu />
      </div>
    </header>
  )
}

function NavLink({ children, ...props }: Omit<ComponentProps<typeof Link>, 'className'>) {
  return (
    <Link
      {...props}
      className='underline decoration-transparent underline-offset-4 transition duration-75 hover:decoration-current'
    >
      {children}
    </Link>
  )
}

function DesktopMenu() {
  return (
    <div className='text-light relative hidden h-full items-center gap-7 text-xl font-semibold lg:flex'>
      <NavLink href='/'>Home</NavLink>
      <NavLink href='/blog'>Blog</NavLink>
      <NavLink href='/about'>About Us</NavLink>
      <NavLink href='/value-branding'>Value Branding</NavLink>
      <NavLink href='/social-media-management'>Social Media Management</NavLink>
      <Link className='btn relative active:brightness-150 text-xl' href='/contact'>
        LET'S TALK
      </Link>
    </div>
  )
}

function MobileMenu() {
  const [menuOpen, setMenuOpen] = useState(false)
  const router = useRouter()
  const menuState = menuOpen ? 'open' : 'closed'

  useEffect(() => {
    const routeChangeHandler = () => {
      setMenuOpen(false)
    }

    router.events.on('routeChangeComplete', routeChangeHandler)
    return () => {
      router.events.off('routeChangeComplete', routeChangeHandler)
    }
  }, [router, setMenuOpen])

  return (
    <div className='flex h-full items-stretch gap-4 lg:hidden'>
      <div
        className='fixed left-0 top-0 h-screen w-screen bg-black/30 data-[state=closed]:hidden'
        data-state={menuState}
        onClick={() => setMenuOpen(false)}
      />
      <nav
        data-state={menuState}
        className='bg-twilight-900 text-light absolute left-full top-0 flex h-screen w-72 flex-col items-end gap-9 px-8 pt-24 text-2xl font-semibold transition-transform duration-150 data-[state=open]:-translate-x-full'
      >
        <div className='flex-[0.1]' />
        <Link className='touch-padding' href='/'>
          Home
        </Link>
        <Link className='touch-padding' href='/blog'>
          Blog
        </Link>
        <Link className='touch-padding' href='/about'>
          About Us
        </Link>
        <Link className='touch-padding' href='/value-branding'>
          Value Branding
        </Link>
        <Link className='touch-padding text-right' href='/social-media-management'>
          Social Media Management
        </Link>
        <div className='flex-[4]' />
        <Link className='touch-padding text-saffron-500' href='/contact'>
          Contact
        </Link>
        <div className='flex-[3]' />
      </nav>
      <div className='relative flex items-center justify-center overflow-hidden rounded-xl'>
        <Link className='btn tap-highlight active:brightness-150 text-xl' href='/contact'>
          LET'S TALK
        </Link>
      </div>
      <Hamburger
        duration={0.2}
        color={'white'}
        toggled={menuOpen}
        onToggle={setMenuOpen}
        size={40}
        distance='lg'
        rounded
      />
    </div>
  )
}

function Logo() {
  const [isTouching, setIsTouching] = useState(false)

  return (
    <Link
      href='/'
      onTouchStart={() => setIsTouching(true)}
      onTouchEnd={() => setIsTouching(false)}
      data-touching={isTouching}
      className='tap-transparent mouse:hover:scale-110 relative transition data-[touching=true]:brightness-75'
    >
      <LogoMark height='64px' />
    </Link>
  )
}
